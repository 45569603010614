<template>
  <section v-if="dataLoaded || campaignData">

    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'browse-campaigns' }">
              {{ resourceNamePlural }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'view-campaign', params: { id: resourceId } }">
              My {{ resourceName }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              Edit
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col
        md="12"
        class="mb-2 text-right"
      >
        <b-button
          variant="outline-danger"
          @click="deleteCampaign(campaignData)"
        >
          <feather-icon
            icon="XIcon"
            size="16"
            class="align-text-top"
          /> Delete {{ resourceName }}
        </b-button>
        <b-button
          variant="primary"
          class="ml-1"
          :to="{ name: 'view-campaign', params: { id: resourceId } }"
        >
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="align-text-top"
          />  View {{ resourceName }}
        </b-button>
        <!-- <b-button
          variant="primary"
          :to="{name: 'browse-campaigns'}"
        >
          <feather-icon
            icon="ListIcon"
            size="16"
            class="align-text-top"
          />  Manage {{ resourceNamePlural }}
        </b-button> -->
      </b-col>
    </b-row>

    <!-- edit resource card  -->
    <b-card class="p-1">
      <b-card-title class="pl-1 border-left-primary border-left-3">
        Edit {{ resourceName }}
      </b-card-title>
      <!-- form -->
      <validation-observer
        ref="resourceForm"
        #default="{invalid, dirty}"
      >
        <b-form @submit.prevent="updateCampaign">
          <b-row>
            <!-- name -->
            <b-col cols="12">
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                  vid="name"
                >
                  <b-form-input
                    id="name"
                    v-model="campaignData.name"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="company name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- start date -->
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="Start Date"
                rules="required"
                vid="start_date"
              >
                <DateTimeForm
                  v-model="campaignData.start_date"
                  prefix="Start"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- end date -->
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="End Date"
                rules="required"
                vid="end_date"
              >
                <DateTimeForm
                  v-model="campaignData.end_date"
                  prefix="End"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- submit -->
            <b-col
              cols="12"
              class="mt-1 text-right"
            >
              <b-button
                :to="{ name: 'view-campaign', params: { id: resourceId } }"
                variant="outline-secondary"
              >
                Cancel
              </b-button>
              <b-button
                class="ml-1"
                type="submit"
                variant="primary"
                :disabled="invalid || !dirty"
              >
                Update
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BCard,
  BFormGroup, BFormInput, BForm, BButton, BCardTitle,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import DateTimeForm from '@/views/components/date-time-form/DateTimeForm.vue'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCardTitle,

    // validations
    ValidationProvider,
    ValidationObserver,

    // date time
    DateTimeForm,
  },
  data() {
    return {
      resourceName: 'Campaign',
      resourceNamePlural: 'Campaigns',
      adminAccess: false,
      adminSuffix: '', // 'AsRoot' if root user
      userData: JSON.parse(localStorage.getItem('userData')),

      required,

      dataLoaded: false,
      errorModalMessage: null,
    }
  },
  computed: {
    resourceId() {
      return this.$route.params.id
    },
    campaignData() {
      return this.$store.getters['campaigns/getCampaign']
    },
  },
  created() {
    if (this.userData.role.id === 1) {
      this.adminAccess = true
      this.adminSuffix = 'AsRoot'
    }

    // fetch campaign
    const campaignId = this.$route.params.id
    this.$store.dispatch(`campaigns/fetchCampaign${this.adminSuffix}`, campaignId)
      .catch(error => {
        console.log('error', error.response)
      })
  },
  methods: {
    updateCampaign() {
      this.$refs.resourceForm.validate().then(success => {
        if (success) {
          // reset form validation state
          // so we can track if form data is dirty
          this.$refs.resourceForm.reset()
          useJwt[`updateCampaign${this.adminSuffix}`](
            this.resourceId,
            this.campaignData,
          )
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Updated',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Campaign updated successfully.',
                },
              })
              this.$router.push({ name: 'view-campaign', params: { id: this.resourceId } })
            })
            .catch(error => {
              if (error.response.status === 400) {
                // highlights all applicable erroneous inputs mentioned by the server.
                this.$refs.resourceForm.setErrors(error.response.data.error)
              }
            })
        }
      })
    },
    deleteCampaign(campaignData) {
      // confirm alert
      this.$swal({
        title: 'Delete campaign?',
        text: `"${campaignData.name}" will deleted. This action cannot be reverted.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt[`deleteCampaign${this.adminSuffix}`](
            campaignData.id,
          )
            .then(() => {
              // const responseData = response.data.data
              // console.log('deleteCampaign', responseData)

              // confirm message
              this.$swal({
                icon: 'success',
                title: 'Done!',
                text: 'Your campaign has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$router.push({ name: 'browse-campaigns' })
            })
            .catch(error => {
              if (error.response.status === 400) {
                // TODO: alert user to errors
                console.log(error.response.data.error)
              }
            })
        }
      })
    },
    showError() {
      this.$bvModal.msgBoxOk(this.errorModalMessage, {
        title: 'Error',
        hideHeaderClose: true,
        okVariant: 'danger',
        centered: true,
        modalClass: 'modal-warning',
      })
    },
  },
}
</script>

<style lang="scss">

</style>
